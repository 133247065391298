// src/components/DayView.js

import React from 'react';
import Calendar from './Calendar';
import EventList from './EventList';
import RandomImage from './RandomImage';

const DayView = () => {
  return (
    <div className="container spacer">
      <h2>Ajourd'hui</h2>
      <Calendar currentDate={new Date()} />
      <EventList view="day" />
      <RandomImage />
    </div>
  );
};

export default DayView;
