import React from 'react';
import { format, startOfWeek, addDays, startOfMonth, endOfMonth, eachDayOfInterval, parseISO, getDay, isToday } from 'date-fns';
import { fr } from 'date-fns/locale';
import events from '../data/events';
import ScrollToTodayButton from './scrollToTodayButton';

const getEventsForDate = (date) => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  const eventForDate = events.find(event => event.date === formattedDate);
  return eventForDate ? eventForDate.event : [];
};

const EventList = ({ view }) => {
  let testDate = null; // Testing purpose, doesn't highlight the day but change the display accordingly. new Date('2024-8-14')
  const currentDate = testDate ? new Date(testDate) : new Date();

  let dates = [];
  let progressValue = 0;
  let daysPassedInMonth = 0, totalWeekdaysInMonth = 0;
  let daysPassedInAllDates = 0, totalWeekdaysInAllDates = 0;

  switch (view) {
    case 'day':
      dates = [{
        date: format(currentDate, 'yyyy-MM-dd'),
        event: getEventsForDate(currentDate),
      }];
      break;
    case 'week':
      const start = startOfWeek(currentDate, { weekStartsOn: 1 });
      const end = addDays(start, 4); // Lundi à vendredi
      
      dates = eachDayOfInterval({ start, end }).map(day => {
        const formattedDay = format(day, 'yyyy-MM-dd');
        
        // Calculer la progression en fonction du jour actuel
        if (isToday(day)) {
          progressValue = getDay(day) ; // Lundi = 1 -> Progress = 1; Vendredi = 5 -> Progress = 5
        }

        return {
          date: formattedDay,
          event: getEventsForDate(day),
          week: events.find(event => event.date === formattedDay)?.week // Récupérer la valeur de 'week'
        };
      });
      break;
    case 'month':
      const firstDayOfMonth = startOfMonth(currentDate);
      const lastDayOfMonth = endOfMonth(currentDate);
    
      const startMonth = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
      const endMonth = addDays(startOfWeek(addDays(lastDayOfMonth, 6 - getDay(lastDayOfMonth)), { weekStartsOn: 1 }), 6);
    
      // Calculer tous les jours ouvrables dans le mois
      const allWeekdaysInMonth = eachDayOfInterval({ start: startMonth, end: endMonth })
        .filter(day => getDay(day) !== 0 && getDay(day) !== 6);
        
        daysPassedInMonth = allWeekdaysInMonth.filter(day => day <= currentDate).length;
        totalWeekdaysInMonth = allWeekdaysInMonth.length;
        
        dates = allWeekdaysInMonth.map(day => {
          const formattedDay = format(day, 'yyyy-MM-dd');
          return {
            date: formattedDay,
            event: getEventsForDate(day),
            week: events.find(event => event.date === formattedDay)?.week
          };
        });
      break;
    case 'all':
      // Trouver la première et la dernière date des événements
      const sortedEvents = events.sort((a, b) => new Date(a.date) - new Date(b.date));
      const startDate = new Date(sortedEvents[0].date);
      const endDate = new Date(sortedEvents[sortedEvents.length - 1].date);
    
      const allWeekdaysInAllDates = eachDayOfInterval({ start: startDate, end: endDate })
        .filter(day => getDay(day) !== 0 && getDay(day) !== 6);

      daysPassedInAllDates = allWeekdaysInAllDates.filter(day => day <= currentDate).length;
      totalWeekdaysInAllDates = allWeekdaysInAllDates.length;

      // Générer toutes les dates entre startDate et endDate, en excluant les weekends
      dates = eachDayOfInterval({ start: startDate, end: endDate })
        .filter(day => getDay(day) !== 0 && getDay(day) !== 6) // Exclure dimanche (0) et samedi (6)
        .map(day => ({
          date: format(day, 'yyyy-MM-dd'),
          event: getEventsForDate(day),
          week: events.find(event => event.date === format(day, 'yyyy-MM-dd'))?.week // Récupérer la valeur de 'week'
        }));
      break;
    default:
      break;
  }

  const formattedDate = (dateString) => {
    const date = parseISO(dateString);
    const day = format(date, 'eeee', { locale: fr });
    const dateOfMonth = format(date, 'dd', { locale: fr });
    const month = format(date, 'MMMM', { locale: fr });
    // const year = format(date, 'yyyy', { locale: fr });

    return (
      <div className='cardEvent--date'>
        <span className="dateDay">{day}</span>
        <span className="dateDate">{dateOfMonth}</span>
        <span className="dateMonth">{month}</span>
        {/* <span className="dateYear">{year}</span> */}
      </div>
    );
  };

  return (
    <div>
    {view === 'week' && (
      <div className="progress-container">
        <label htmlFor="week-progress">Progression de la semaine :</label>
        <progress id="week-progress" value={progressValue} max="5"></progress>
      </div>
    )}
    {view === 'month' && (
      <div>
        <div className="scrollToToday">
          <ScrollToTodayButton /> 
        </div>
        <div className="progress-container">
          <label htmlFor="month-progress">Progression du mois :</label>
          <progress id="month-progress" value={daysPassedInMonth} max={totalWeekdaysInMonth}></progress>
        </div>
      </div>
    )}
    {view === 'all' && (
      <div>
        <div className="scrollToToday">
          <ScrollToTodayButton /> 
        </div>
        <div className="progress-container">
          <label htmlFor="all-progress">Progression de toutes les dates :</label>
          <progress id="all-progress" value={daysPassedInAllDates} max={totalWeekdaysInAllDates}></progress>
        </div>
      </div>
      )}
    <div className={`containerCards ${view !== "day" ? 'spacer' : ''}`}>
      {dates.map((event, index) => {
        const dateObj = parseISO(event.date);
        const isWeekend = getDay(dateObj) === 6 || getDay(dateObj) === 0;

        return (
          <div key={index} id={isToday(dateObj) && view !== 'day' ? 'today' : undefined} className={`cardEvent ${isToday(dateObj) && view !== 'day' ? 'today' : ''}`}>
            {view !== "day" ? formattedDate(event.date) : null}
            <div className='cardEvent--event'>
              {view !== "day" ? (<p className='weekNumber'>Semaine {event.week}</p>) : null}
              {view === "day" && isWeekend ? (
                <p className='eventName'><em>C'est le weekend, reposez-vous bien !</em></p>
              ) : event.event.length > 0 ? (
                <>
                  {event.event.map((e, i) => (
                    <div key={i}>
                      <p className={`eventName ${e.type ? `${e.type}` : ''}`}>{e.name}</p>
                    </div>
                  ))}
                </>
              ) : (
                <p className='eventName'><em>Journée normale de 9h à 16h</em></p>
              )}
            </div>
          </div>
        );
      })}
    </div>
    </div>
  );
};

export default EventList;