import React, { useEffect, useState } from 'react';

const ScrollToTodayButton = () => {
  const [isTodayVisible, setIsTodayVisible] = useState(false);

  useEffect(() => {
    const checkTodayElement = () => {
      const todayElement = document.getElementById('today');
      setIsTodayVisible(!!todayElement); // Vérifie si l'élément 'today' existe
    };

    const scrollToToday = (e) => {
      e.preventDefault();

      const todayElement = document.getElementById('today');
      if (todayElement) {
        const yOffset = -300; // Décalage de 100px
        const y = todayElement.getBoundingClientRect().top + window.scrollY + yOffset;
        
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }
    };

    // Vérifier la présence de l'élément 'today' lors du montage
    checkTodayElement();

    const button = document.getElementById('scrollToToday');
    if (button) {
      button.addEventListener('click', scrollToToday);
    }

    // Nettoyage de l'événement lors du démontage
    return () => {
      if (button) {
        button.removeEventListener('click', scrollToToday);
      }
    };
  }, []);

  return (
    <>
      {isTodayVisible && (
        <a href="#today" id="scrollToToday">
          Aller à la semaine en cours
        </a>
      )}
    </>
  );
};

export default ScrollToTodayButton;