import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/scrollToTopButton';
import DayView from './components/DayView';
import WeekView from './components/WeekView';
import MonthView from './components/MonthView';
import AllDatesView from './components/AllDatesView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faCalendarWeek, faCalendarDays as fasCalendarDaysSolid } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays as fasCalendarDaysRegular } from '@fortawesome/free-regular-svg-icons';


function App() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <div>
        <header>
          <NavLink to="/">
            <h1>Agenda Sirius 2024</h1>
          </NavLink>
        </header>
        <nav>
          <ul>
            <NavLink to="/">
              <li className='iconDay'>
                <FontAwesomeIcon icon={faCalendarDay}  />
              </li>
            </NavLink>
            <NavLink to="/week">
              <li className='iconWeek'>
                  <FontAwesomeIcon icon={faCalendarWeek} />
              </li>
            </NavLink>
            <NavLink to="/month">
              <li className='iconMonth'>
                  <FontAwesomeIcon icon={fasCalendarDaysSolid} />
              </li>
            </NavLink>
            <NavLink to="/all">
              <li className='iconAll'>
                  <FontAwesomeIcon icon={fasCalendarDaysRegular} />
              </li>
            </NavLink>
          </ul>
        <ScrollToTopButton />
        </nav>
        <Routes>
          <Route path="/" element={<DayView />} />
          <Route path="/week" element={<WeekView />} />
          <Route path="/month" element={<MonthView />} />
          <Route path="/all" element={<AllDatesView />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;