import React from 'react';

// Utilisation de require.context pour importer toutes les images du dossier
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../assets/images', false, /\.(png|jpe?g|svg)$/));

const RandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  const selectedImage = images[randomIndex];

  return (
    <div className="randomImage">
      <h2>Meme aléatoire</h2>
      <a href={selectedImage} target="_blank" rel='noreferrer'>
       <img src={selectedImage} alt="Random Meme" />
      </a>
    </div>
  );
};

export default RandomImage;